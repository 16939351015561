import { createSlice } from "@reduxjs/toolkit";
import Logger from "../Utilities/Logger";
// import UserInfo from "../Users/UserInfo";

export const generateOrderedKey = (menuKey, menuOptionsChecked) => {
  let orderKey = menuKey;
  if (menuOptionsChecked != undefined && menuOptionsChecked.length > 0) {
    menuOptionsChecked.forEach((element) => {
      orderKey += "_op_" + element;
    });
  }

  return orderKey;
};

export const OrderedItemSlice = createSlice({
  name: "orderedItems",
  initialState: {
    data: [],
    itemsInCart: {},
    currentCounts: [],
    menuOptions: {},
    menuOptionsChecked: {},
    isShowCartItems: false,
    isShowLogin: false,
    isShowCreateAccount: false,
    isShowOption: false,
    optionMenuKey: -1,
    locationId: "",
    appId: "",
    opMode: "",
    storeCreditCategory: -1,
    verificationInfo: { verificationToken: "", contact: "", isVerified: false },
    userInfo: {
      userId: "LooBapBap Guest",
      userKey: -1,
      contact: "",
      fName: "",
      lName: "",
    },
    paymentMethod: "online",
  },
  reducers: {
    incrementQuantity: (state, action) => {
      if (!state.currentCounts[action.payload]) {
        state.currentCounts[action.payload] = 0;
      }
      state.currentCounts[action.payload]++;
    },
    incrementQuantityInCart(state, action) {
      if (state.itemsInCart[action.payload])
        state.itemsInCart[action.payload].count++;
    },
    decrementQuantity: (state, action) => {
      if (!state.currentCounts[action.payload]) {
        state.currentCounts[action.payload] = 0;
      }
      state.currentCounts[action.payload]--;
      if (state.currentCounts[action.payload] < 0)
        state.currentCounts[action.payload] = 0;
    },
    decrementQuantityInCart(state, action) {
      if (state.itemsInCart[action.payload])
        state.itemsInCart[action.payload].count--;
    },
    addOrder: (state, action) => {
      let orderedKey = generateOrderedKey(
        action.payload.menuKey,
        state.menuOptionsChecked[action.payload.menuKey]
      );

      if (!state.itemsInCart[orderedKey]) {
        let inCartItems = { ...state.itemsInCart };
        let item = {
          menuKey: action.payload.menuKey,
          count: 0,
          catId: action.payload.catId,
        };
        inCartItems[orderedKey] = item;
        state.itemsInCart = inCartItems;
      }

      let currentCount = 0;
      if (state.currentCounts[action.payload.menuKey] > 0) {
        currentCount = state.currentCounts[action.payload.menuKey];
      }
      state.itemsInCart[orderedKey].count += currentCount;
      state.itemsInCart[orderedKey].catId = action.payload.catId;
      state.itemsInCart[orderedKey].optionsSelected =
        state.menuOptionsChecked[action.payload.menuKey];
      state.menuOptionsChecked[action.payload.menuKey] = [];

      state.currentCounts[action.payload.menuKey] = 0;
      // action.payload.setMenusCookie("ItemsInCart", state.itemsInCart, {
      //   path: "/",
      //   maxAge: 10 * 60,
      // });
    },
    updateCatItems: (state, action) => {
      let cat = action.payload.id;

      state.data[action.payload.id] = {
        items: action.payload.items,
        id: action.payload.id,
      };

      let cunnentCounts = [...state.currentCounts];
      let initCartItems = { ...state.itemsInCart };
      action.payload.items.forEach((item) => {
        cunnentCounts[item.PKEY] = 0;
        initCartItems[item.PKEY] = {
          menuKey: item.PKEY,
          count: 0,
          catId: cat,
        };
      });
      state.currentCounts = cunnentCounts;
      state.itemsInCart = initCartItems;

      //update all menu items info from cookies
      let menuItems = action.payload.menuItems;
      if (menuItems && menuItems.length > 0) {
        state.itemsInCart = menuItems;
      }
    },
    updateOptionItems: (state, action) => {
      if (action.payload.id >= 0) {
        state.menuOptions[action.payload.id] = action.payload.items;
      }
    },
    updateCheckedOptionItems: (state, action) => {
      if (action.payload.checkedState) {
        if (state.menuOptionsChecked[action.payload.menuKey] == undefined) {
          state.menuOptionsChecked[action.payload.menuKey] = [];
        }
        var index = state.menuOptionsChecked[action.payload.menuKey].indexOf(
          action.payload.optionKey
        );
        if (index === -1) {
          state.menuOptionsChecked[action.payload.menuKey].push(
            action.payload.optionKey
          );
        }
      } else if (state.menuOptionsChecked[action.payload.menuKey]) {
        var index = state.menuOptionsChecked[action.payload.menuKey].indexOf(
          action.payload.optionKey
        );
        if (index > -1) {
          state.menuOptionsChecked[action.payload.menuKey].splice(index, 1);
        }
      }
    },
    resetItemsInCart: (state, action) => {
      state.itemsInCart = {};
    },
    resetOptions: (state, action) => {
      state.menuOptionsChecked[action.payload.menuKey] = [];
    },
    setIsShowCart: (state, action) => {
      state.isShowCartItems = action.payload;
    },
    setIsShowLogin: (state, action) => {
      state.isShowLogin = action.payload;
    },
    setIsShowCreateAccount: (state, action) => {
      state.isShowCreateAccount = action.payload;
    },
    setIsShowOption: (state, action) => {
      state.isShowOption = action.payload.isShowOption;
      state.optionMenuKey = action.payload.optionMenuKey;
      // state.menuOptions = action.payload.items;
    },
    registerToken: (state, action) => {
      state.locationId = action.payload.locationId;
      state.appId = action.payload.appId;
      state.opMode = action.payload.opMode;
    },
    UpdateUserInfo: (state, action) => {
      Logger.Log("UpdateUserInfo action.payload:", action.payload);
      state.userInfo.userId = action.payload.userId;
      state.userInfo.userKey = action.payload.userKey;
      state.userInfo.contact = action.payload.contact;
      state.userInfo.fName = action.payload.fName;
      state.userInfo.lName = action.payload.lName;
    },
    signOut: (state, action) => {
      state.userInfo = {
        userId: "LooBapBap Guest",
        userKey: -1,
        contact: "",
        fName: "",
        lName: "",
      };
      state.verificationInfo = {
        verificationToken: "",
        contact: "",
        isVerified: false,
      };
    },
    registerVerificationToken: (state, action) => {
      state.verificationInfo.verificationToken =
        action.payload.verificationToken;
      state.verificationInfo.contact = action.payload.contact;
      state.verificationInfo.isVerified =
        action.payload.isVerified != undefined &&
        action.payload.isVerified === true;
    },
    updatePaymentMethod: (state, action) => {
      state.paymentMethod = action.payload.paymentMethod;
    },
    updateStoreCreditCategory: (state, action) => {
      state.storeCreditCategory = action.payload.storeCreditCategory;
    },
  },
});

export const {
  incrementQuantity,
  incrementQuantityInCart,
  decrementQuantity,
  decrementQuantityInCart,
  addOrder,
  updateCatItems,
  updateOptionItems,
  updateCheckedOptionItems,
  resetItemsInCart,
  resetOptions,
  setIsShowCart,
  setIsShowLogin,
  setIsShowOption,
  setIsShowCreateAccount,
  registerToken,
  UpdateUserInfo,
  signOut,
  updatePaymentMethod,
  updateStoreCreditCategory,
  registerVerificationToken,
} = OrderedItemSlice.actions;

export default OrderedItemSlice.reducer;
